<template>
  <div class="ContactDetails">
    <CModal
      :show.sync="isContactModified"
      :no-close-on-backdrop="true"
      title="Contact modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre contact a bien été modifié 👍
      </p>
      <p>
        Vous avez bien modifié votre contact. Vous pouvez rester sur votre page contact ou retourner à la liste des contact de votre cabinet.
      </p>
      <template #footer>
        <CButton @click="isContactModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manage-contacts/')" color="dark">Retour à la liste des contacts</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isContactArchived"
      :no-close-on-backdrop="true"
      title="Contact archivé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre contact a bien été archivé ⛔
      </p>
      <p>
        Aucun collaborateur ne pourra plus associer de clients ou de missions à ce contact.
      </p>
      <template #footer>
        <CButton @click="$router.push('/manage-contacts/')" color="primary">Retour à la liste des contacts</CButton>
        <CButton @click="isContactArchived = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isContactActivated"
      :no-close-on-backdrop="true"
      title="Contact réactivé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre contact a bien été réactivé 🥳
      </p>
      <p>
        Tous vos collaborateurs le verront dans la liste des contacts et pourront créer des missions et des clients
        associés à ce contact.
      </p>
      <template #footer>
        <CButton @click="isContactActivated = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manage-contacts/')" color="dark">Retour à la liste des contacts</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CCard v-if="!contact.is_active">
      <CCardHeader>
        <slot name="header">
          <strong>Votre contact a été archivé</strong>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CButton
          :color="activateContactButtonStyle"
          shape="pill" block class="px-4"
          @click='activateContact'
          :disabled="activateContactInProcess">
          <CSpinner size="sm" label="archive client spinner" v-if="activateContactInProcess"></CSpinner>
          {{ activateContactButtonText }}
        </CButton>
      </CCardBody>
    </CCard>
    
    <CRow class="mb-3">
      <CCol>
        <h1> {{contact.first_name}} {{contact.last_name}} </h1>
      </CCol>
    </CRow>
    
    <CCard>
      <CCardBody>
        <CForm>
          <CRow>
            <CCol sm="4">
              <CSelect
                :value.sync="modifyContactSex"
                :disabled="!contact.is_active"
                @input="$v.modifyContactSex.$touch()"
                :isValid="$v.modifyContactSex.$dirty ? !$v.modifyContactSex.$error : null"
                :options='sexArrayForm'
                custom
              />
            </CCol>
            <CCol sm="4">
              <CInput
                v-model="modifyContactFirstName" type="text"
                maxlength="30"
                :disabled="!contact.is_active"
                @input="$v.modifyContactFirstName.$touch()"
                :isValid="$v.modifyContactFirstName.$dirty ? !$v.modifyContactFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="4">
              <CInput
                v-model="modifyContactLastName" type="text"
                maxlength="150"
                :disabled="!contact.is_active"
                @input="$v.modifyContactLastName.$touch()"
                :isValid="$v.modifyContactLastName.$dirty ? !$v.modifyContactLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>



          <CRow>
            <CCol sm="6">
              <CInput
                label="Email"
                type="email"
                maxlength="150"
                :disabled="!contact.is_active"
                placeholder="Email"
                v-model="modifyContactEmail"
                @input="$v.modifyContactEmail.$touch()"
                :isValid="$v.modifyContactEmail.$dirty ? !$v.modifyContactEmail.$error : null"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Fonction (facultatif)"
                type="text"
                maxlength="149"
                :disabled="!contact.is_active"
                placeholder="Fonction"
                v-model="modifyContactFonction"
                @input="$v.modifyContactFonction.$touch()"
                :isValid="$v.modifyContactFonction.$dirty ? !$v.modifyContactFonction.$error : null"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="6">
              <label>Votre date de naissance (facultatif) </label>
              <CRow>
                <CCol sm="3">
                  <CSelect
                    :value.sync="modifyContactBirthdateDay"
                    :options='daysArrayForm'
                    custom
                  />
                </CCol>
                <CCol sm="6">
                  <CSelect
                    :value.sync="modifyContactBirthdateMonth"
                    :options='monthsArrayForm'
                    custom
                  />
                </CCol>
                <CCol sm="3">
                  <CSelect
                    :value.sync="modifyContactBirthdateYear"
                    :options='yearsArrayForm'
                    custom
                  />
                </CCol>
              </CRow>
              <small class="text-danger" v-if="$v.birthdateFormat.$invalid ">Veuillez saisir une date valide</small>

            </CCol>
            <CCol lg="6">
              <div role="group" class="form-group">
                <label for="modifyContactVuePhoneNumber" class>
                  Numéro de téléphone
                </label>
                <vue-phone-number-input
                  id="modifyContactVuePhoneNumber"
                  name="modifyContactVuePhoneNumber"
                  v-model="modifyContactPhoneNumber"
                  :default-country-code='modifyContactPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='modifyContactPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="modifyContactPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>

          <span> Client actuel associé : </span>
          <strong> {{actualContactClientName}} </strong>

          <vue-bootstrap-typeahead
            class="mt-2"
            v-model="modifyContactClientName"
            :data="clients"
            :serializer="item => item.name"
            @hit="modifyContactClientObject = $event"
             placeholder="Chercher votre client par nom"
          />

          <CTextarea
            class="mt-3"
            label="Observations"
            v-model="modifyContactObservations" type="text"
            placeholder="Vous pouvez renseigner des observations si nécessaire"
            maxlength="299"
            :disabled="!contact.is_active"
            @input="$v.modifyContactObservations.$touch()"
            :isValid="$v.modifyContactObservations.$dirty ? !$v.modifyContactObservations.$error : null"
          >
          </CTextarea>

          <CRow  v-if="contact.is_active" class="mt-4">
            <CCol sm="6">
              <CButton
                :color="modifyContactButtonStyle"
                shape="pill" block class="px-4"
                @click='modifyContact'
                :disabled="$v.modifyContactSex.$invalid || $v.modifyContactFirstName.$invalid || $v.modifyContactLastName.$invalid || $v.modifyContactEmail.$invalid || $v.modifyContactFonction.$invalid || $v.birthdateFormat.$invalid || $v.modifyContactPhoneNumberResult.$invalid || $v.modifyContactObservations.$invalid || modifyContactInProcess">
                <CSpinner size="sm" label="update profile spinner" v-if="modifyContactInProcess"></CSpinner>
                {{ modifyContactButtonText }}
              </CButton>
            </CCol>
            <CCol sm="6">
              <CButton
                :color="archiveContactButtonStyle"
                shape="pill" block class="px-4"
                @click='archiveContact'
                :disabled="archiveContactInProcess">
                <CSpinner size="sm" label="update profile spinner" v-if="archiveContactInProcess"></CSpinner>
                {{ archiveContactButtonText }}
              </CButton>
              <small class="text-danger">
                Attention, si vous archivez ce contact, aucun collaborateur
                ne pourra plus le modifier, ni créer de clients ou missions liés avec ce contact.
              </small>
            </CCol>
          </CRow>
          <CRow  v-else class="mt-4">
            <CCol class="text-center">
              <strong> Votre contact est désactivé</strong>
            </CCol>
          </CRow>

        </CForm>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, integer, email} from 'vuelidate/lib/validators'
import { APIUserConnected } from '@/api/APIUserConnected'
import { isDateOrNullOrBlank } from '@/validators/validators'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()


export default {
  name: 'ContactDetails',
  components: {
    Loading,
    VueBootstrapTypeahead
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      // ---- Contact ---------
      isContactLoading: false,
      contact: {},

      // ---- Modify Contact ---
      isContactModifying: false,
      isContactModified: false,

      modifyContactClient: 'false',
      modifyContactSex: 'H',
      modifyContactFirstName: '',
      modifyContactLastName: '',
      modifyContactEmail: '',
      modifyContactFonction: '',
      modifyContactBirthdateYear: '',
      modifyContactBirthdateMonth: '',
      modifyContactBirthdateDay: '',
      modifyContactPhoneNumber: null,
      modifyContactPhoneNumberResult: {
        countryCode: "FR",
        isValid: true
      },
      modifyContactObservations: '',

      sexArrayForm: [
        { label: 'Monsieur', value: 'H' },
        { label: 'Madame', value: 'F' },
      ],

      // Clients
      clients: [],
      actualContactClientName: '',
      modifyContactClientName: '',
      modifyContactClientObject: {
        id: '',
        name: ''
      },

      /*clients: [],
      clientsSelectOptions: [
        { label: 'Aucun client associé', value: "false"}
      ],*/

      modifyContactButtonText: 'Modifier votre contact',
      modifyContactButtonStyle: 'outline-primary',
      modifyContactInProcess: false,

      // ---- Archive Contact ---
      isContactArchived: false,
      archiveContactButtonText: 'Archiver votre contact',
      archiveContactButtonStyle: 'outline-danger',
      archiveContactInProcess: false,

      // ---- Activate Contact ---
      isContactActivated: false,
      activateContactButtonText: 'Réactiver votre contact',
      activateContactButtonStyle: 'outline-primary',
      activateContactInProcess: false,
      
      monthsArrayForm: [
        { label: '', value: '' },
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],
      
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isContactLoading) {
        return true
      }
      return false
    },
    
    // ---- Date 
    daysArrayForm () {
      var days = [{'label': '', 'value': ''}]
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'label': value, 'value': value})
      }
      return days
    },
    
    yearsArrayForm () {
      var years = [{'label': '', 'value': ''}]
      for (var i=1945; i<=2010; i++) {
        var value = String(i)
        years.push({'label': value, 'value': value})
      }
      return years
    },
    
    birthdateFormat () {
      if (this.modifyContactBirthdateYear == '' && this.modifyContactBirthdateMonth == '' && this.modifyContactBirthdateDay == '') {
        return ''
      }
      return this.modifyContactBirthdateYear + '-' + this.modifyContactBirthdateMonth + '-' + this.modifyContactBirthdateDay
    }
  },
  validations: {
    modifyContactSex: {
      required
    },
    modifyContactFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    modifyContactLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
    modifyContactEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    modifyContactFonction: {
      minLength: minLength(2),
      maxLength: maxLength(149)
    },
    birthdateFormat: {
      validDate(value) {
        return isDateOrNullOrBlank(value)
      }
    },
    modifyContactPhoneNumberResult: {
      countryCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      },
      nationalNumber: {
        required,
        integer
      }
    },
    modifyContactObservations: {
      minLength: minLength(2),
      maxLength: maxLength(300)
    },
    modifyContactClientObject: {
      id: {
        required
      }
    }
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getContact()
  },

  watch: {
    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    modifyContactClientName(newQuery) {
      if (newQuery == '') {
        this.clients = []
        this.modifyContactClientObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.clients = res.data
        })
      }
    },
  },

  methods: {
    // ------------- Getters --------------
    getContact () {
      this.isContactLoading = true
      apiUserConnected.getContact(this.token, this.$route.params.id)
      .then((result) => {
        this.contact = result.data
        if (this.contact.client) {
          this.actualContactClientName = this.contact.client.name
          this.modifyContactClientName = this.contact.client.name
          this.modifyContactClientObject = {
            id: this.contact.client.id,
            name: this.contact.client.name
          }
        }
        else {
          this.actualContactClientName = ''
          this.modifyContactClientName = ''
          this.modifyContactClientObject = {
            id: '',
            name: ''
          }
        }
        this.modifyContactSex = this.contact.sex
        this.modifyContactFirstName = this.contact.first_name
        this.modifyContactLastName = this.contact.last_name
        this.modifyContactEmail = this.contact.email
        this.modifyContactFonction = this.contact.fonction
        if (this.contact.birthdate) {
          var birthdate = this.$dayjs(this.contact.birthdate, 'YYYY-MM-DD')
          this.modifyContactBirthdateYear = birthdate.format('YYYY')
          this.modifyContactBirthdateMonth = birthdate.format('MM')
          this.modifyContactBirthdateDay = birthdate.format('DD')
        }
        this.modifyContactPhoneNumber = this.contact.phone_number
        this.modifyContactPhoneNumberResult = {
          countryCode: this.contact.phone_country_code,
          isValid: true,
          nationalNumber: this.contact.phone_number
        }
        this.modifyContactObservations = this.contact.observations
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isContactLoading = false
      })
    },

    // ---------- Modify contact -----------
    modifyContact () {
      this.modifyContactButtonText = 'Modification en cours'
      this.modifyContactButtonStyle = 'secondary'
      this.modifyContactInProcess = true
      apiUserConnected.modifyContact(this.token,
        this.contact.id, this.modifyContactClientObject.id, this.modifyContactSex, this.modifyContactFirstName, this.modifyContactLastName,
        this.modifyContactEmail, this.modifyContactFonction, this.birthdateFormat, this.modifyContactPhoneNumberResult.countryCode,
        this.modifyContactPhoneNumberResult.nationalNumber, this.modifyContactObservations)
      .then(() => {
        this.getContact()
        this.isContactModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyContactButtonText = 'Modifier votre contact'
        this.modifyContactButtonStyle = 'outline-primary'
        this.modifyContactInProcess = false
      })
    },

    // ---------- Archive contact -----------
    archiveContact () {
      this.archiveContactButtonText = 'Archivage en cours'
      this.archiveContactButtonStyle = 'secondary'
      this.archiveContactInProcess = true
      apiUserConnected.archiveContact(this.token,
        this.contact.id)
      .then(() => {
        this.getContact()
        this.isContactArchived = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.archiveContactButtonText = 'Archiver votre contact'
        this.archiveContactButtonStyle = 'outline-danger'
        this.archiveContactInProcess = false
      })
    },

    // ---------- ACTIVATE client -----------
    activateContact () {
      this.activateContactButtonText = 'Activation en cours'
      this.activateContactButtonStyle = 'secondary'
      this.activateContactInProcess = true
      apiUserConnected.activateContact(this.token, this.contact.id)
      .then(() => {
        this.getContact()
        this.isContactActivated = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.activateContactButtonText = 'Réactiver votre contact'
        this.activateContactButtonStyle = 'outline-primary'
        this.activateContactInProcess = false
      })
    },
  }
}
</script>
